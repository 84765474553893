// konstanta maximálního počtu zobrazení notifikací
export const SHOW_MAX_COUNT_NOTIFICATIONS = 4;

let settings: any = {
    endpointUnread: undefined, //? unused
    endpointGotoApprove: undefined,  //? unused
    endpointGotoShow: undefined,
    websocketsServer: undefined,
    endpointCounts: undefined, //? unused
    endpointUnreadMessages: undefined
}

export const getSettings = () => {
    return settings;
}

export const setSettings = () => {
    settings = {...settings, ...document.getElementById('reactNotifications')!.dataset};
}