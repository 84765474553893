import Axios, { AxiosResponse, AxiosError } from "axios";

// GET request

export const fetchDataGET = async (url: string) => {
    return await Axios.get(url).then((response: AxiosResponse) => {
        return Promise.resolve(response.data);
    }).catch((error: AxiosError) => {
        console.log(error);
    });
} 