import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {fetchDataGET} from './helpers/api';
import {getTexts} from './texts/texts';
import {getSettings, setSettings} from './helpers/settings';
import sockets from './helpers/sockets';
import {showNotifications} from './helpers/notifications';
import {redirectTo} from './helpers/general';

// styly

import './app.scss';

// root komponenta

const App = () => {

    const [messages, setMessages] = useState<{count: number, unread: UnreadMessage[]} | null>(null);

    const fetchData = (ignoreNotification = false) => {
        setMessages(null);
        fetchDataGET(getSettings().endpointUnreadMessages).then((data: EndpointUnreadMessages) => {
            if (!ignoreNotification && (data.unread)) {
                if (data.unread) {
                    showNotifications(data);
                }
            }
            setMessages({unread: data.unread, count: data.count});
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {

        setSettings();
        fetchData();

        // systémové notifikace

        if ((window.Notification && Notification.requestPermission)) {
            Notification.requestPermission().then(function (result) {
                // if (result === 'granted') {
                // 
                // }
            });
        }

        // připojení k socketům 

        sockets.connect(() => {

            // odebírání 

            sockets.subscribe((...data: any) => {

                const parsedData = JSON.parse(data);

                if (parsedData.action === 'NEW_BULK_MESSAGE') {
                    fetchData();
                }

            });


            // odpojení při opuštění stránky

            window.addEventListener('beforeunload', () => {
                sockets.unsubscribe();
            });
        }, (error: any) => console.error(error));

        window.addEventListener('focus', () => {
            fetchData(true);
        });

    }, []);

    const totalCount = messages?.count;

    const {notifications, viewAllMessage} = getTexts();
    const {endpointGotoShow} = getSettings();

    const selectedMessages = messages?.unread.slice(0, 4) || [];

    return <div className="nav-item dropdown">
        <a className="nav-link dropdown-toggle hide-arrow px-1 mr-lg-2 ml-2 ml-lg-0" id="reactNotificationsLink" data-toggle="dropdown" aria-expanded="false" href="#">
            <i className="lnr lnr-envelope navbar-icon align-middle"></i>
            {!!totalCount && <span className="badge badge-danger indicator rounded-pill">{totalCount}</span>}
            <span className="d-lg-none align-middle px-1 mr-lg-2 ml-2 ml-lg-0">{notifications}</span>
        </a>
        <div className="dropdown-menu dropdown-menu-right mx-0 m-lg-0 mb-3">
            {selectedMessages.map(({title, author, created, important, href}: any, index: number) => {
                return (
                    <label
                        key={index}
                        className={`message-block${important ? " important" : ""}`}
                        onClick={() => redirectTo(href)}
                    >
                        <div className="left-side">
                            {/* <i className="lnr lnr-envelope navbar-icon align-middle"></i> */}
                            <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
                        </div>
                        <div className="message-content">
                            <a className="message-title" href={href}>{title}</a>
                            <span className="message-author">{author}</span>
                            <span className="message-date">{created}</span>
                        </div>
                    </label>
                );
            })}
            <label
                className={`message-block`}
                onClick={() => redirectTo(endpointGotoShow)}
            >
                <div className="message-content">
                    <a href={endpointGotoShow}>{viewAllMessage}</a>
                </div>
            </label>
        </div>
    </div>
}

// inicializace / připojení do html elementu
const wrapper = document.getElementById('reactNotifications');

if (wrapper) {
    ReactDOM.render(<App />, document.getElementById('reactNotifications'));
}