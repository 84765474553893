import { getTexts } from "../texts/texts";
import {SHOW_MAX_COUNT_NOTIFICATIONS} from "./settings";

export const showNotification = async (data: any) => {

    const notificationObject = { heading: 'Sportisimo - ' + getTexts().notifications, icon: '/www/img/sportisimo.png' }
    if (window.Notification && Notification.permission === 'granted'){
        try {
            const notification = new Notification(notificationObject.heading, { body: data.text, icon: notificationObject.icon });
            if (data.url){
                notification.onclick = (event) => {
                    event.preventDefault();
                    window.open(data.url, '_blank');
                }
            }
        } catch (e) {

            if(navigator.serviceWorker){

                try {

                    const swRegistered = await navigator.serviceWorker.getRegistration('/');
                    // console.log(swRegistered);
                    if (swRegistered){
                        // console.log('using registered SW');
                        swRegistered.showNotification(notificationObject.heading, { body: data.text, icon: notificationObject.icon, data: data.url ? {url: data.url} : {}});   
                    } else {
                        // console.log('registering SW');
                        await navigator.serviceWorker.register('/serviceWorker.js', {scope: '/'});
                        navigator.serviceWorker.ready.then((serviceregWorkerRegistration) => { 
                            serviceregWorkerRegistration.showNotification(notificationObject.heading, { body: data.text, icon: notificationObject.icon, data: data.url ? {url: data.url} : {} });
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }
}

export const showNotifications = (data: EndpointUnreadMessages) => {

    const ids = data.unread.map((item) => item.id);

    const checkedNotifications = JSON.parse(localStorage.getItem('sportisimoNotificationChecked') || '[]');

    const notifications: NotificationData[] = data.unread.filter(item => (
        Array.isArray(checkedNotifications) &&
        checkedNotifications.indexOf(item.id) === -1
    )).map(item => ({text: item.title, url: item.href}));

    if (notifications.length) {
        notifications.splice(0, SHOW_MAX_COUNT_NOTIFICATIONS).forEach(notification => (
            showNotification(notification)
        ));
    }

    localStorage.setItem('sportisimoNotificationChecked', JSON.stringify(ids));

}