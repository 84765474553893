import { getSettings } from "./settings";

const Sockets = class {

        connected: boolean = false;
        socket: any = null;
        subscribed: boolean = false;

    // přiojit k socketům

    connect(callbackOk: (...params: any) => any, callbackNok: (...params: any) => any) {
            this.socket = new WebSocket(getSettings().websocketsServer);
            this.socket.onerror = (error: any) => {
                callbackNok(error);
            };
            this.socket.onopen = () => {
                this.connected = true;
                callbackOk();
            };    
    }

    // odebírat zprávy

    subscribe(callback: (...params: any) => any) {
        if (!this.connected) {
            return;
        }
        // console.log(this.socket);
        this.subscribed = true;
        this.socket.onmessage = (event: any) => {
            callback(event.data);
        };
    }

    // neodebírat zprávy

    unsubscribe() {
        this.connected = false;
        this.socket.close();
        this.socket = false;
        this.subscribed = false;
    }

    // odeslat data

    send(data: any) {
        if  (this.connected) {
            this.socket.send(JSON.stringify(data));
        }
    }

};

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

const sockets = new Sockets();

export default sockets;